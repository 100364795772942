<template>
  <section class="homepage-section">
    <div class="heading">
      <TwinColorTitle>Tips on traveling <br v-if="mobile"><em>your way</em></TwinColorTitle>
      <a v-if="desktop" class="see-more" href="https://www.tourhero.com/en/magazine/" target="_blank">
        <Button color="white" text="See more"/>
      </a>
    </div>

    <div class="posts">
      <a class="post" href="https://www.tourhero.com/en/magazine/lifestyle/hosting-a-trip-with-tourhero-8-epic-benefits-you-should-know/" target="_blank">
        <div class="poster">
          <img src="app/assets/images/home/blogpost_1.jpg">
          <div class="btn">
            <div><Arrow/></div>
          </div>
        </div>
        <div class="caption">
          <div>8 Ways hosting a trip with TourHero will benefit you</div>
          <div>August 7, 2024</div>
        </div>
      </a>
      <a class="post" href="https://www.tourhero.com/en/magazine/lifestyle/what-is-tourhero-everything-you-need-to-know-about-becoming-one/" target="_blank">
        <div class="poster">
          <img src="app/assets/images/home/blogpost_2.jpg">
          <div class="btn">
            <div><Arrow/></div>
          </div>
        </div>
        <div class="caption">
          <div>What is TourHero? Everything you need to know</div>
          <div>August 6, 2024</div>
        </div>
      </a>
      <a class="post" href="https://www.tourhero.com/en/magazine/lifestyle/how-to-get-paid-to-travel-6-effective-ways-to-make-money/" target="_blank">
        <div class="poster">
          <img src="app/assets/images/home/blogpost_3.jpg">
          <div class="btn">
            <div><Arrow/></div>
          </div>
        </div>
        <div class="caption">
          <div>How to get paid to travel the world</div>
          <div>August 29, 2024</div>
        </div>
      </a>
    </div>

    <a v-if="mobile" class="see-more" href="https://www.tourhero.com/en/magazine/" target="_blank">
      <Button color="white" text="See more"/>
    </a>
  </section>
</template>

<script setup>
import TwinColorTitle from "@/components/Theme2/TwinColorTitle.vue";
import Button from "@/components/Theme2/Button.vue";
import Arrow from "app/assets/images/home/arrow.svg?component";
import { useShared } from "@/helpers/vueHelpers.js";

const { mobile, desktop } = useShared();
</script>

<style lang="scss" scoped>
section.homepage-section {
  gap: 32px;

  .heading {
    display: flex;
    justify-content: space-between;

    @include mobile {
      justify-content: center;
    }
  }

  .posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .post {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 12px;

    .poster {
      display: flex;
      position: relative;
    }

    .caption {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div:nth-child(1) {
        color: $black;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px; /* 116.667% */
        height: 56px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      div:nth-child(2) {
        color: $grey-100;
      }
    }

    .btn {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: white;
      padding: 8px 0 0 8px;
      border-top-left-radius: 32px;

      div {
        padding: 20px;
        width: 65px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $grey-25;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 22px;
        }
      }
    }

    &:hover .btn div {
      background-color: $green-50;
    }

    img {
      width: 100%;
      border-radius: 12px;
    }
  }

  .see-more {
    @include mobile {
      margin: 0 auto;
    }

    button {
      border-color: #D4D4D4;
    }
  }
}
</style>