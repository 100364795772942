export default class Flash {
  constructor() {
    const params = new URLSearchParams(window.location.search);
    if (!params.get('error')) return;

    this.errorType = params.get('error');
    this.createBanner();
  }

  createBanner() {
    if (!this.errorMessage) return;

    const siteNav = document.querySelector('.site-nav');
    if (!siteNav) return;

    const div = document.createElement('div');
    div.setAttribute('class', 'flash flash--alert js-flash');
    const inner = document.createElement('div');
    inner.setAttribute('class', 'flash__inner');
    const icon = document.createElement('i');
    icon.setAttribute('class', 'icon-alert-triangle');
    const p = document.createElement('p');
    p.setAttribute('class', 'flash__message');
    p.innerHTML = this.errorMessage;

    const button = document.createElement('button');
    button.setAttribute('class', 'flash__button js-close-button');
    button.setAttribute('type', 'button');

    const iconClose = document.createElement('i');
    iconClose.setAttribute('class', 'icon-close');

    siteNav.after(div);
    div.append(inner);
    inner.append(icon, p, button);
    button.append(iconClose);
  }

  get errorMessage() {
    switch (this.errorType) {
      case 'invalid_invitation':
        return 'Invalid invitation';
    }
    return '';
  }
}
