<template>
  <section class="homepage-section">
    <TwinColorTitle class="title">
      Transform your ideas <br><em>into reality</em>
    </TwinColorTitle>

    <StepSliderText :steps/>

    <FloatingContainer>
      <div class="images">
        <img src="app/assets/images/home/started_1.jpg">
        <img src="app/assets/images/home/started_2.jpg">
        <img src="app/assets/images/home/started_3.jpg">
      </div>
    </FloatingContainer>
  </section>
</template>

<script setup>
import TwinColorTitle from "@/components/Theme2/TwinColorTitle.vue";
import StepSliderText from "@/components/StepSliderText.vue";
import FloatingContainer from "@/components/FloatingContainer.vue";

const steps = [
  `Book a call; tell us your preference`,
  `We'll present itinerary options; choose the one you love most for further customization`,
  `Launch your trip by sharing with your community! We'll take care of the rest`,
];
</script>

<style lang="scss" scoped>
section.homepage-section {
  align-items: center;
  margin-bottom: 64px;

  @include mobile {
    @include main-unwrap;
    overflow-x: hidden;
  }

  .title {
    margin-bottom: 32px;
  }

  .images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
    overflow: hidden;

    img {
      width: 100%;
    }

    @include mobile {
      @include main-unwrap;

      img {
        width: 156px;
      }
    }
  }

  &:deep(.steps) {
    justify-content: center;
  }
}
</style>